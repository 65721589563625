import React from 'react';
import '../components/layout.scss';
import Layout from '../components/layout';
import * as styles from './impression-tous-supports.module.scss';
import SEO from '../components/seo';
import administratif from '../images/produits/tous-supports/administratif.jpg';
import carterie from '../images/produits/tous-supports/carterie.jpg';
import etiquette from '../images/produits/tous-supports/etiquette.jpg';
import evenementiel from '../images/produits/tous-supports/evenementiel.jpg';
import marketing from '../images/produits/tous-supports/marketing.jpg';
import multiPages from '../images/produits/tous-supports/multi-pages.jpg';
import objetPub from '../images/produits/tous-supports/objet-publicitaire.jpg';
import restauration from '../images/produits/tous-supports/restauration.jpg';
import speciaux from '../images/produits/tous-supports/support-speciaux.jpg';
import textile from '../images/produits/tous-supports/textile.jpg';

const TousSupportsPage = () => (
  <Layout>
    <SEO title="Produits Tous Supports" />

    <section>
      <div className="large-container">
        <div className="row">
          <div className="col-sm-12 text-center">
            <h1 className="static-h1">
              Tous supports de communication et publicitaires
            </h1>
            <p>
              Trouvez tous les produits dont vous avez besoin pour réussir votre
              campagne de communication globale.
            </p>
            <div className={styles.categories}>
              <div>
                <button
                  onClick={() => animateScroll('#papier')}
                  className={`${styles.btn} ${styles.btnSecondary}`}
                >
                  <strong>Papier</strong>
                </button>
                <button
                  onClick={() => animateScroll('#etiquettes')}
                  className={`${styles.btn} ${styles.btnSecondary}`}
                >
                  <strong>Étiquettes</strong>
                </button>
                <button
                  onClick={() => animateScroll('#textile')}
                  className={`${styles.btn} ${styles.btnSecondary}`}
                >
                  <strong>Textile</strong>
                </button>
                <button
                  onClick={() => animateScroll('#salons')}
                  className={`${styles.btn} ${styles.btnSecondary}`}
                >
                  <strong>Évènementiel et salons</strong>
                </button>
                <button
                  onClick={() => animateScroll('#objets-pub')}
                  className={`${styles.btn} ${styles.btnSecondary}`}
                >
                  <strong>Objets publicitaires</strong>
                </button>
                <button
                  onClick={() => animateScroll('#speciaux')}
                  className={`${styles.btn} ${styles.btnSecondary}`}
                >
                  <strong>Supports spéciaux</strong>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="large-container">
        <div className="card" id="papier">
          <h2 className="text-center">Papier</h2>
          <div className={styles.papier}>
            <div className="card bg-white">
              <img src={multiPages} />
              <h3>Documents multi-pages </h3>
              <ul>
                <li>Brochures</li>
                <li>Catalogues</li>
                <li>Magazines</li>
                <li>Guides</li>
                <li>Livrets</li>
                <li>Manuels</li>
              </ul>
            </div>
            <div className="card bg-white">
              <img src={marketing} />
              <h3>Documents marketing et commerciaux </h3>
              <ul>
                <li>Flyers, prospectus, tracts</li>
                <li>Plaquettes</li>
                <li>Dépliants multi-volets</li>
                <li>Affiches petit format</li>
                <li>Cartons d'invitation</li>
                <li>Pochettes à rabat</li>
              </ul>
            </div>
            <div className="card bg-white">
              <img src={administratif} />
              <h3>Imprimés administratifs, papeterie et autres documents</h3>
              <ul>
                <li>Papier à en-tête</li>
                <li>Enveloppes</li>
                <li>Bloc-notes</li>
                <li>Carnets</li>
                <li>Carnets et liasses autocopiants</li>
                <li>Sous-mains</li>
                <li>Calendriers</li>
              </ul>
            </div>
            <div className="card bg-white">
              <img src={carterie} />
              <h3>Carterie</h3>
              <ul>
                <li>Cartes de visite et de correspondance</li>
                <li>Faire-part</li>
                <li>Cartes de voeux</li>
                <li>Cartes postale</li>
                <li>Marque-pages</li>
              </ul>
            </div>
            <div className="card bg-white">
              <img src={restauration} />
              <h3>Restauration</h3>
              <ul>
                <li>Menus</li>
                <li>Sets de table</li>
                <li>Sous-boks</li>
              </ul>
            </div>
          </div>
        </div>
        <div className={styles.produits} id="etiquettes">
          <div className="card">
            <img src={etiquette} />
            <h2>Étiquettes</h2>
            <ul>
              <li>Standard</li>
              <li>Stickers</li>
              <li>En rouleau</li>
              <li>Sur mesure</li>
              <li>Express 24h</li>
            </ul>
          </div>
          <div className="card" id="textile">
            <img src={textile} />
            <h2>Textile</h2>
            <ul>
              <li>T-shirts</li>
              <li>Polos</li>
              <li>Casquettes</li>
              <li>Maillots de foot</li>
            </ul>
          </div>
          <div className="card" id="salons">
            <img src={evenementiel} />
            <h2>Évènementiel et salons</h2>
            <ul>
              <li>Chevalets</li>
              <li>Présentoirs pour flyers</li>
              <li>Bracelets Tyvek (indéchirable)</li>
              <li>Minis Roll-ups</li>
              <li>Minis X-banners</li>
              <li>Mini-drapeaux</li>
              <li>Drapeaux et guirlandes de fanions</li>
              <li>Badges personnalisables</li>
            </ul>
          </div>
          <div className="card" id="objets-pub">
            <img src={objetPub} />
            <h2>Objets publicitaires</h2>
            <ul>
              <li>Tote bags (sacs publicitaires)</li>
              <li>Bracelets</li>
              <li>Stylos plastique et métal</li>
              <li>Gobelets plastique et mugs</li>
              <li>Ballons de baudruche</li>
              <li>Sous-bocks</li>
              <li>Magnets frigo</li>
            </ul>
          </div>
          <div className="card" id="speciaux">
            <img src={speciaux} />
            <h2>Supports spéciaux </h2>
            <ul>
              <li>Cartes PVC, transparentes ou magnétiques</li>
              <li>Stop rayon</li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  </Layout>
);

export default TousSupportsPage;

function animateScroll(targetSelector) {
  const targetPosition = getElementOffsetTop(
    document.querySelector(targetSelector)
  );

  console.log('targetPosition', targetPosition);

  const initialPosition = window.scrollY;
  const SCROLL_DURATION = 30;
  const maxDistance = document.body.scrollHeight - window.innerHeight;

  const scrollDistance =
    maxDistance > targetPosition
      ? targetPosition - initialPosition
      : maxDistance;
  const stepX = Math.PI / SCROLL_DURATION;
  let stepCount = 0;
  if (window.requestAnimationFrame) {
    requestAnimationFrame(step);
  }

  function step() {
    if (stepCount < SCROLL_DURATION) {
      requestAnimationFrame(step);
      stepCount++;
      window.scrollTo(
        0,
        initialPosition +
          scrollDistance * 0.25 * Math.pow(1 - Math.cos(stepX * stepCount), 2)
      );
    }
  }
}

const getElementOffsetTop = (element, currentOffset = 0) => {
  console.log('element', element);

  if (element.offsetParent) {
    return getElementOffsetTop(
      element.offsetParent,
      currentOffset + element.offsetTop
    );
  }

  return currentOffset;
};
